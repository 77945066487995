import { createApp } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import vueDebounce from 'vue-debounce';
import App from './App.vue';
import router from './router';
import store from './store';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/styles/style.scss';

const app = createApp(App);

(app as any).use(AOS.init());

app.use(vueDebounce, { listenTo: 'input' });

app.use(store).use(router).mount('#app');
