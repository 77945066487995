
import { Options, Vue } from 'vue-class-component';
import ApiCalls from '@/services/api-calls';
import Partners from '@/components/Partners.vue';
import Loader from '@/components/Loader.vue';

@Options({
  components: {
    Loader,
    Partners,
  },
})
export default class Navbar extends Vue {
  // mainImage = 'https://i.ibb.co/93rkL4w/savvas-kalimeris-f6-CHIHltz70-unsplash.jpg';

  api = new ApiCalls();

  searchRef: any;

  searchResults: {id: number, label: string}[] | null = null;

  isSearchResultsLoading = false;

  onSearch(v: string): void {
    if (!v) {
      this.searchResults = null;
      return;
    }
    this.api.simpleTextSearch(v).then((response) => {
      this.searchResults = response.map((x: any) => ({ id: x['o:id'], label: x['o:title'] }));
      this.isSearchResultsLoading = false;
    });
  }

  onSearchImmediateChange(v: string): void {
    this.searchResults = null;
    this.isSearchResultsLoading = !!v;
  }

  onSelectItem(id: number): void {
    this.$router.push(`/item/${id}`);
  }
}
