
import { Vue, Options } from 'vue-class-component';
import Partners from './Partners.vue';

@Options({
  components: {
    Partners,
  },
})
export default class Footer extends Vue {
}
