import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import(/* webpackChunkName: "collection" */ '../views/Collection.vue'),
  },
  {
    path: '/tags/:tags?',
    name: 'Tags',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Tags.vue'),
  },
  {
    path: '/item/:id',
    name: 'Item',
    component: () => import(/* webpackChunkName: "item" */ '../views/Item.vue'),
  },
  {
    path: '/media/:id',
    name: 'MediaItem',
    component: () => import(/* webpackChunkName: "mediaitem" */ '../views/MediaItem.vue'),
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "map" */ '@/views/Map.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorPage',
    component: () => import(/* webpackChunkName: "errorpage" */ '../views/ErrorPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach((to, from) => {
  if (to.path.includes('redirection/')) {
    console.log('from redirection!!!!!');
    router.push({ path: to.path.split('redirection/').join('') });
    return;
  }

  window.scrollTo({ top: 0, behavior: 'smooth' });
});

export default router;
