import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e519a47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "d-flex flex-column h-100",
  "data-aos": "fade-up"
}
const _hoisted_3 = { class: "d-flex align-items-center justify-content-center flex-grow-1" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-2" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-lg mt-5 mt-lg-0",
        key: partner.img
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "partner-logo mx-auto",
              src: partner.img,
              alt: "partner logo"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              href: partner.url,
              target: "_blank"
            }, _toDisplayString(partner.label), 9, _hoisted_6)
          ])
        ])
      ]))
    }), 128))
  ]))
}