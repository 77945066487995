import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { Config, Category } from '@/models';

export default createStore({
  state: {
    // config: null as { title: string, configuration: any } | null,
    config: null as Config | null,
    categories: null as Category[] | null,
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
  },
  getters: {
    isConfigLoaded: (context) => !!context.config,
    getSiteTitle: (context) => (context.config ? context.config.title : ''),
    getSiteLogo: (context) => (context.config ? context.config.configuration.logo : ''),
    getSiteConfiguration: (context) => (context.config ? context.config.configuration : null),
    getItemsPerPage: (context) => (context.config ? context.config.configuration.itemsPerPage : null),
    getCategories: (context) => context.categories,
    getRegisteredVocabs: (context) => (context.config ? context.config.configuration.registeredVocabs : null),
  },
  // actions: {
  // },
  // modules: {
  // },
  plugins: [createPersistedState()],
});
