
import { Vue } from 'vue-class-component';

export default class Partners extends Vue {
  partners = [
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/hermes.png', url: 'https://www.thinkhermes.com/', label: 'HERMeS' },
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/dipylon.png', url: 'https://dipylon.org/', label: 'Δίπυλον' },
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/monumenta.jpg', url: 'https://www.monumenta.org/', label: 'MONUMENTA' },
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/melissa.png', url: 'https://www.melissabooks.com/', label: 'Μέλισσα' },
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/get.png', url: 'https://www.getmap.eu', label: 'GET' },
    { img: 'https://repo.in-athens.gr/manage/application/asset/img/logos/commonspace.png', url: 'https://www.commonspace.gr/', label: 'Common Space' },
  ];
}
