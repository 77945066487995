
import { Options, Vue } from 'vue-class-component';
import ApiCalls from '@/services/api-calls';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import store from '@/store';

// todo: disable app until getting config

@Options({
  components: { Navbar, Footer },
})
export default class App extends Vue {
  api = new ApiCalls();

  async created(): Promise<void> {
    try {
      const configResponse = await this.api.getSiteConfiguration();
      if (!configResponse[0]['o:summary'] || !configResponse[0]['o:title']) throw new Error('Invalid Configuration in Omeka Site Info');

      const config: {
        title: string,
        configuration: any,
      } = {
        title: configResponse[0]['o:title'],
        configuration: JSON.parse(configResponse[0]['o:summary']),
      };

      document.title = config.title;

      console.log('config', config);
      store.commit('setConfig', config);

      const categoriesResponse = await this.api.getResourceTemplates();
      const includedResourceTemplates = store.getters.getSiteConfiguration.resourceTemplates;
      if (!includedResourceTemplates) console.log('undefined resource templates');
      const categories = categoriesResponse.filter((x: any) => includedResourceTemplates.includes(x['o:label'])).map((x: any) => ({
        id: x['o:id'],
        label: x['o:label'],
        properties: x['o:resource_template_property'].map((y: any) => ({
          id: y['o:property']['o:id'],
          label: y['o:alternate_label'] || 'no label specified',
        })),
      }));
      console.log('categories', categories);

      store.commit('setCategories', categories);
    } catch (err) {
      console.log('An error occurred', err);
    }
  }
}
