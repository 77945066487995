import Api from './api';

export default class ApiCalls extends Api {
  public getSiteConfiguration(): Promise<any> {
    const url = '/manage/api/sites';

    return this.get<any>(url).then((response) => response.data);
  }

  // public getCategories(): Promise<any> {
  //   const url = '/manage/api/item_sets';

  //   return this.get<any>(url).then((response) => response.data);
  // }

  // public getItems(): Promise<any> {
  //   const url = '/manage/api/items';

  //   return this.get<any>(url).then((response) => response.data);
  // }

  public getItem(id: number): Promise<any> {
    const url = `/manage/api/items/${id}`;

    return this.get<any>(url).then((response) => response.data);
  }

  public getItemsByResourceTemplate(resourceTemplateId: number, itemsPerPage: number, page = 0): Promise<any> {
    const url = `/manage/api/items?resource_template_id=${resourceTemplateId}&page=${page + 1}&per_page=${itemsPerPage === Infinity ? 999999999999 : itemsPerPage}`;

    return this.get<any>(url).then((response) => ({
      items: response.data,
      itemsCount: parseInt(response.headers['omeka-s-total-results'], 10),
    }));
  }

  // public getItemsByTag(tag: string, itemsPerPage: number, page = 0) {
  //   const url = `/manage/api/items?tag=${tag}&page=${page + 1}&per_page=${itemsPerPage}`;

  //   return this.get<any>(url).then((response) => ({
  //     items: response.data,
  //     itemsCount: parseInt(response.headers['omeka-s-total-results'], 10),
  //   }));
  // }

  public getItemsByTags(tags: string[], itemsPerPage: number, page = 0) {
    const url = `/manage/api/items?tag=${tags.join(',')}&page=${page + 1}&per_page=${itemsPerPage}`;

    return this.get<any>(url).then((response) => ({
      items: response.data,
      itemsCount: parseInt(response.headers['omeka-s-total-results'], 10),
    }));
  }

  public getMarkerLocation(markerId: number): Promise<any> {
    const url = `/manage/api/mapping_markers/${markerId}`;

    return this.get<any>(url).then((response) => response.data);
  }

  public getMarkers(): Promise<any> {
    const url = '/manage/api/mapping_markers?page=1&per_page=999999999';

    return this.get<any>(url).then((response) => response.data);
  }

  public getMedia(mediaId: number): Promise<any> {
    const url = `/manage/api/media/${mediaId}`;

    return this.get<any>(url).then((response) => response.data);
  }

  public getVocabularies(): Promise<any> {
    const url = '/manage/api/vocabularies';

    return this.get<any>(url).then((response) => response.data);
  }

  public getPropertiesByVocabulary(vocabularyId: number): Promise<any> {
    const url = `/manage/api/properties?vocabulary_id=${vocabularyId}`;

    return this.get<any>(url).then((response) => response.data);
  }

  public getResourceTemplates(): Promise<any> {
    const url = '/manage/api/resource_templates';

    return this.get<any>(url).then((response) => response.data);
  }

  public getResourceTemplateById(id: number): Promise<any> {
    const url = `/manage/api/resource_templates/${id}`;

    return this.get<any>(url).then((response) => response.data);
  }

  public getTags() {
    const url = '/manage/api/tags?page=1&per_page=999999999999';

    return this.get<any>(url).then((response) => response.data);
  }

  public simpleTextSearch(q: string) {
    const url = `/manage/api/items?search=${q}`;

    return this.get<any>(url).then((response) => response.data);
  }

  // IMPORTANT
  // currently, the search parameter is 'fulltext_search' for items and 'search' for media
  public search(params: {
    type: 'items' | 'media',
    search: string,
    valueParams: {
      joiner?: 'and' | 'or',
      propertyId: number,
      type: string,
      text: string,
    }[]
  }, itemsPerPage: number, page = 0): Promise<any> {
    const endpoint = `/manage/api/${params.type}`;

    let queryString = '';
    queryString += `${params.type === 'items' ? 'fulltext_search' : 'search'}=${params.search || ''}&per_page=${itemsPerPage}&page=${page + 1}`;

    const valueParams = params.valueParams.filter((x) => x.propertyId && x.type && x.text);
    if (valueParams.length) {
      // eslint-disable-next-line
      queryString += '&' + params.valueParams.map((x, i) =>
      // eslint-disable-next-line
      `property[${i}][joiner]=${x.joiner || 'and'}&`
      + `property[${i}][property]=${x.propertyId}&`
      + `property[${i}][type]=${x.type}&`
      + `property[${i}][text]=${x.text}`).join('&');
    }
    console.log('q', queryString);

    const url = `${endpoint}?${queryString}`;

    return this.get<any>(url).then((response) => ({
      items: response.data,
      itemsCount: parseInt(response.headers['omeka-s-total-results'], 10),
    }));
  }
}
