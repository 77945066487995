import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError,
} from 'axios';

const baseUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASEURL : '';

const handleError = <T>(err: AxiosError): Promise<AxiosResponse<T>> => {
  console.log(err);

  throw err;
};

export default class Api {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({ withCredentials: true });
  }

  protected get<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.api.get<R>(`${baseUrl}${url}`, config)
      .catch((err: AxiosError) => handleError(err));
  }

  protected delete<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.api.delete<R>(`${baseUrl}${url}`, config)
      .catch((error: AxiosError) => handleError(error));
  }

  protected post<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.api.post<T, AxiosResponse<R>>(`${baseUrl}${url}`, data, config)
      .catch((error: AxiosError) => handleError(error));
  }

  protected put<T, R>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.api.put<T, AxiosResponse<R>>(`${baseUrl}${url}`, data, config)
      .catch((error: AxiosError) => handleError(error));
  }
}
