
import { Vue } from 'vue-class-component';
import store from '@/store';

export default class Navbar extends Vue {
  isNavbarOpen = false;

  scrollPosition = 0;

  isNavbarDark(): boolean {
    // if (this.scrollPosition !== 0 && this.$route.path === '/') return true;
    if (this.scrollPosition !== 0 || this.$route.path !== '/') return true;
    return false;
  }

  beforeMount(): void {
    window.addEventListener('scroll', this.updateScrollPosition);
  }

  unmounted(): void {
    window.removeEventListener('scroll', this.updateScrollPosition);
  }

  updateScrollPosition(): void {
    this.scrollPosition = window.scrollY;
  }
}
